<template>
  <div>
    <PageTitlebar
      title="Business Settings"
      title-icon="sym_r_settings"
      sub-title="Keep your business information up to date."
    >
      <!--<template v-slot:bottom>
        <small class="block q-mt-sm text-font-secondary">
          <b>Created On:</b> {{ convertUtcToBusinessTimezone($store.state.business.record.time_created, "lll") }}
        </small>
      </template>-->
    </PageTitlebar>

    <q-form @submit="editBusiness">
      <FormCard
        title="Business Details"
        message="Some basic information about your account."
      >
        <!--<template v-slot:message>
          Some basic information about your account.
          <PageBanner
            title="Please Note"
            message="This address will be used for billing."
            type="info"
            class="q-mt-md"
            flat
            bordered
          />
        </template>-->

        <template v-slot:inputs>
          <q-input
            filled
            v-model="formData.name"
            label="Business Name"
            lazy-rules
            :rules="[
              val =>
                (val && val.length > 1) || 'Enter a name for your business.'
            ]"
            :disable="submitting"
          />
          <q-select
            filled
            v-model="formData.timezone"
            :options="[
              {
                label: 'America/Chicago',
                value: 'America/Chicago'
              },
              {
                label: 'America/Denver',
                value: 'America/Denver'
              },
              {
                label: 'America/Los Angeles',
                value: 'America/Los_Angeles'
              },
              {
                label: 'America/New York',
                value: 'America/New_York'
              }
            ]"
            label="Timezone"
            emit-value
            map-options
            :rules="[val => (val && val.length > 0) || 'Select a Timezone.']"
            :disable="submitting"
          />
          <q-input
            filled
            v-model="formData.billingEmail"
            label="Billing Email Address"
            lazy-rules
            :rules="[
              val =>
                (val && val.length > 1) ||
                'Enter a billing email address for this business.'
            ]"
            :disable="submitting"
            hint="Enter a billing email address for this business. You will be billed net 30 for the previous month's spend."
          />
        </template>
      </FormCard>

      <FormActionsSection>
        <template v-slot:primary-actions>
          <q-btn
            label="Save Changes"
            type="submit"
            color="primary"
            :disable="submitting"
            :loading="submitting"
          />
        </template>
        <template v-slot:secondary-actions>
          <q-btn
            label="Delete Business"
            @click="promptDeleteBusiness()"
            color="negative"
            :disable="submitting"
            :loading="submitting"
          />
        </template>
      </FormActionsSection>
    </q-form>
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import FormCard from "@/components/UI/FormCard.vue";
import FormActionsSection from "@/components/UI/FormActionsSection.vue";
import { Dialog } from "quasar";
import ConfirmationModal from "@/components/UI/ConfirmationModal.vue";
// import PageBanner from "@/components/UI/PageBanner.vue";

export default {
  name: "AdvertiserBusinessSettings",
  components: {
    FormActionsSection,
    // PageBanner,
    FormCard,
    PageTitlebar
  },
  data() {
    return {
      loading: true,
      submitting: false,
      formData: {
        name: this.$store.state.business.record.name,
        timezone: this.$store.state.business.record.timezone,
        billingEmail: this.$store.state.business.record.billing_email
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    editBusiness() {
      this.submitting = true;

      this.$store
        .dispatch("business/POST_REQUEST", {
          endpoint: "update",
          params: {
            id: this.$store.state.business.record.id,
            name: this.formData.name,
            timezone: this.formData.timezone,
            billing_email: this.formData.billingEmail
          }
        })
        .then(() => {
          this.$store.dispatch("business/POPULATE_RECORD");
          this.triggerActionOutcomeAlert("save", true);
        })
        .catch(e => {
          console.log(e);
          this.triggerActionOutcomeAlert("save", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    promptDeleteBusiness() {
      Dialog.create({
        component: ConfirmationModal,
        componentProps: {
          header: "Confirm Business Deletion",
          subHeader: this.$store.state.business.record.name,
          message:
            "You're about to delete this business. As a result, all the accounts, sources, and users will be permanently deleted. This action cannot be undone. Any outstanding account balances at the time of deletion will be charged to the payment methods on file for those accounts. These payments will be processed within 24 hours after deletion. <b>Are you sure you want to continue?</b>",
          buttonLabel: "Delete Business",
          buttonColor: "negative",
          includeConfirmationInput: true
        }
      }).onOk(dialog => {
        dialog.submitting = true;
        this.$store
          .dispatch("business/POST_REQUEST", {
            endpoint: "delete",
            params: { id: this.$store.state.business.record.id }
          })
          .then(() => {
            this.$router.push({ name: "Logout" });
            dialog.hide();
          })
          .catch(() => {
            this.triggerActionOutcomeAlert("delete", false);
          })
          .finally(() => {
            dialog.submitting = false;
          });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
